import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MatSelectionList } from "@angular/material/list";
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';

import {map, Observable, startWith} from "rxjs";

import { Customer, GrantComponent } from '../../../core/models';
import { CustomersService, GrantComponentsService } from "../../../core/services";
import { listAnimation } from "../../../core/animations/list-animation";
import {
  ApprovalConfirmationSettingsDialogComponent,
  ArchiveSettingsDialogComponent,
  AutomationSettingsDialogComponent,
  CommentsSettingsDialogComponent,
  CriteriaSettingsDialogComponent,
  ForecastSettingsDialogComponent,
  PaymentReceiptsSettingsDialogComponent,
  PortalSettingsDialogComponent,
  ProfileSettingsDialogComponent,
  RecommendationsSettingsDialogComponent,
  RefusalSettingsDialogComponent,
  ScoringSettingsDialogComponent,
  SplitGrantsSettingsDialogComponent,
  TaxSettingsDialogComponent
} from './component-settings';
import {
  BulkActionSettingsDialogComponent
} from "./component-settings/bulk-action-settings-dialog/bulk-action-settings-dialog.component";
import {
  EmployeeNumberSettingsDialogComponent
} from "./component-settings/employee-number-settings-dialog/employee-number-settings-dialog.component";

@Component({
  selector: 'app-customer-components',
  templateUrl: './customer-components.component.html',
  styleUrls: ['./customer-components.component.scss'],
  animations: [listAnimation]
})
export class CustomerComponentsComponent implements OnInit {
  @ViewChild('componentsSelection') componentsSelection: MatSelectionList;
  customer: Customer;

  settingsFormGroup: FormGroup;

  allComponents: GrantComponent[] = [];

  selectedComponents: number[] = [];
  searchString = new FormControl('', {nonNullable: true});
  filteredComponents$: Observable<{name: string, id: number, slug: string }[]>;

  showSelected: boolean = true;

  constructor(private customersService: CustomersService,
              private componentsService: GrantComponentsService,
              private dialog: MatDialog,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.settingsFormGroup = new FormGroup({});
    this.route.data.subscribe((data) => {
      this.customer = data['customer'];
    });
    this.componentsService.getComponents()
      .subscribe(components => {
        this.allComponents = components;
        this.componentsInit();
      });
  }

  selectComponent(componentId: number, checked: boolean) {
    if (checked) {
      this.selectedComponents.push(componentId);
    } else {
      const index = this.selectedComponents.indexOf(componentId);
      this.selectedComponents.splice(index, 1);
    }
    this.saveComponents();
  }

  editComponentSettings(componentSlug: string): void {
    const settingsComponent = this.getSettingsDialog(componentSlug);
    if(null === settingsComponent) {
      return;
    }
    // open dialog
    this.dialog.open(settingsComponent, {
      data: {
        ...this.customer.settings,
        customer: this.customer
      },
      panelClass: 'dialog',
      minWidth: '800px'
    }).afterClosed()
      .subscribe((result) => {
        if (result) {
          if (this.settingsFormGroup.get(componentSlug)) {
            this.settingsFormGroup.removeControl(componentSlug);
          }
          switch(componentSlug) {
            case 'automatic_approval_confirmation':
              this.settingsFormGroup.addControl('approval_confirmation', result);
              break;
            case 'portal':
              this.settingsFormGroup.removeControl('notifications');
              this.settingsFormGroup.addControl('notifications', result);
              break;
            default:
              this.settingsFormGroup.addControl(componentSlug, result);
          }
          this.saveSettings();
        }
      });
  }

  componentsInit(): void {
    this.selectedComponents = this.customer.components.map(c => c.id).sort();
    this.allComponents.sort((a, b) => a.name.localeCompare(b.name));
    this.filteredComponents$ = this.searchString.valueChanges.pipe(startWith(''))
      .pipe(map(search => this.allComponents.filter(c => c.name.toLocaleLowerCase().includes(search.toLowerCase()))));
  }

  saveComponents(): void {
    this.componentsService.saveComponents(this.selectedComponents, this.customer.id)
      .subscribe(value => {
        this.customer = value;
      });
  }

  componentHasSettings(componentSlug: string): boolean {
    return null !== this.getSettingsDialog(componentSlug);
  }

  isSelected(componentSlug: string): boolean {
    return this.customer.components.filter(component => component.slug === componentSlug).length > 0;
  }

  saveSettings(): void {
    this.customer.settings = Object.assign({}, this.customer.settings, this.settingsFormGroup.value);
    this.customersService.update(this.customer)
      .subscribe(value => {
        this.customer = value;
      })
  }

  getSettingsDialog(slug: string): ComponentType<any> | null {
    switch(slug) {
      case 'automatic_approval_confirmation':
        return ApprovalConfirmationSettingsDialogComponent;
      case 'archive':
        return ArchiveSettingsDialogComponent;
      case 'automation':
        return AutomationSettingsDialogComponent;
      case 'comments':
        return CommentsSettingsDialogComponent;
      case 'criteria':
        return CriteriaSettingsDialogComponent;
      case 'forecast':
        return ForecastSettingsDialogComponent;
      case 'payment-receipts':
        return PaymentReceiptsSettingsDialogComponent;
      case 'profile':
        return ProfileSettingsDialogComponent;
      case 'recommendations':
        return RecommendationsSettingsDialogComponent;
      case 'paper-refusal':
        return RefusalSettingsDialogComponent;
      case 'scoring':
        return ScoringSettingsDialogComponent;
      case 'bulk_actions':
        return BulkActionSettingsDialogComponent;
      case 'portal' :
        return PortalSettingsDialogComponent;
      case 'employee_no':
        return EmployeeNumberSettingsDialogComponent;
      case 'split-grants':
        return SplitGrantsSettingsDialogComponent;
      case 'tax-report':
        return TaxSettingsDialogComponent;
      default:
        return null;
    }
  }
}
