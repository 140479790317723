<h2 mat-dialog-title>
  Indstillinger
  <mat-divider></mat-divider>
</h2>
<mat-dialog-content>
  <form [formGroup]="form">
    <p>Opsætning af indstillinger, fx om privatpersoner skal fremgå med navn eller blot som "privatperson" i printet.</p>
    <div fxLayout="row" fxFlexAlign="space-between">
      <div fxFlex="50">
        <div class="mb-2">
          <label id="style">Print layout</label>
          <mat-radio-group formControlName="style" aria-labelledby="style" class="vertical-radio-group">
            <mat-radio-button value="standard">Standard</mat-radio-button>
            <mat-radio-button value="ap">A.P. Møller Fondene</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="mb-2">
          <div>
            <label id="attachment_scale_label">Skalering af bilag ({{formatAttachmentScale}})</label>
          </div>
          <mat-slider min="0.1" max="1" step="0.05"  formControlName="attachment_scale">
            <input matSliderThumb>
          </mat-slider>
        </div>
        <div>
          <mat-checkbox formControlName="show_private_name">Vis navn på privatpersoner</mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="show_private_address">Vis adresse på privatpersoner</mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="warning_multiple_applications">Vis advarsel ved flere, ubehandlere ansøgninger fra samme ansøger.</mat-checkbox>
        </div>
      </div>
      <div fxFlex="50">
        <div class="mb-2">
          <label id="compact_history">Historik-nøgle</label>
          <mat-radio-group formControlName="compact_history" aria-labelledby="compact_history" class="vertical-radio-group">
            <mat-radio-button value="digest">Standard (digest)</mat-radio-button>
            <mat-radio-button value="company_registration_no">CVR-nummer</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="mb-2">
          <mat-checkbox formControlName="hide_priority">Skjul prioritet (rækkefølge)</mat-checkbox>
        </div>
      </div>
    </div>
  </form>
  <div fxFlex class="mt-3 pt-3">
    <div>
      <label id="sub_prioritoies_label">Angiv antallet af underpositioner</label>
    </div>
    <mat-form-field>
      <mat-label>
        Antal af underpositioner
      </mat-label>
      <input type="number" (blur)="setPriorityValues()" [(ngModel)]="subPriorityAmount" matInput [disabled]="hide_priority.value">
      <mat-hint>Værdien 3 angiver værdierne: [1.0, 1.1, 1.2, 1.3, 2.0, 2.1, 2.2, 2.3]</mat-hint>
      <mat-error *ngIf="priorities.hasError('required')"><strong>Skal</strong> udfyldes.</mat-error>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Luk</button>
  <button mat-raised-button color="accent" (click)="doSave()">Gem</button>
</mat-dialog-actions>
